'use strict';

(function() {

	if (document.querySelector('body.embedded') == null) {
		return null;
	}

	var height = 0,
		timeout = 0,
		heightInterval = window.setInterval(_checkHeight, 200);

	function _checkHeight() {
		var body = document.querySelector('[role="main"]'),
				currentHeight = Math.max(body.offsetHeight);

		if (currentHeight !== height) {
			timeout = 0;
			height = currentHeight;
			_updateHeight();
		} else {
			timeout += 1;
			if (timeout > 2000) {
				window.clearInterval(heightInterval);
			}
		}
	}

	function _updateHeight() {
		if (window.parent) {
			window.parent.postMessage({
				'source': window.location.href,
				'event': 'heightChanged',
				'height': height
			}, '*');
		}
	}
}());
