(function () {


  $(document).ready(function () {

    var type = $('body').attr('data-type');
    Arbiter.publish('page/' + type + '/init');

    $('.component').each(function () {
      var type = $(this).attr('data-type');
      Arbiter.publish('component/' + type + '/init', {
        elem: $(this)
      });
    });

    $('.fjs-nav-toggle').click(function () {
      $('.fjs-nav-layer').css('display', 'block');
      $('body').addClass('fjs-nav-open');
    });

    $('.fjs-nav-layer-close').click(function () {
      var navLayer = $(this).closest('.fjs-nav-layer');
      navLayer.css({display:''});
      if (navLayer.hasClass('nav-layer-0')) {
        $('body').removeClass('fjs-nav-open');
      }
    });
  });

  Arbiter.subscribe('page/home/init', function () {

    var activateIndex = function (index) {
      $('.carrousel-item:visible').hide();
      var carrouselItems = $('.carrousel-item');
      var activeItem = $(carrouselItems[index]);
      activeItem.show();
      $('body').css('background-image', 'url(' + activeItem.attr('data-image-url') + ')');
    };

    var navigate = function (direction) {
      var carrouselItems = $('.carrousel-item');
      var activeIndex = 0;
      for (var i = 0; i < carrouselItems.length; i++) {
        if ($(carrouselItems[i]).is(':visible')) {
          activeIndex = i;
          break;
        }
      }
      var newIndex = activeIndex + direction;
      if (newIndex < 0) {
        newIndex = carrouselItems.length - 1;
      } else if (newIndex > (carrouselItems.length - 1)) {
        newIndex = 0;
      }
      activateIndex(newIndex);
    };
    var nextItem = function() {
      navigate(1);
    };

    var slideInterval = setInterval(nextItem, 8000);

    $('i[data-carrousel-item-index]').click(function () {
      clearInterval(slideInterval);
      var index = parseInt($(this).attr('data-carrousel-item-index'));
      activateIndex(index);
    });
    $('.carrousel-nav-left').click(function () {
      clearInterval(slideInterval);
      navigate(-1);
    });
    $('.carrousel-nav-right').click(function () {
      clearInterval(slideInterval);
      navigate(1);
    });

    activateIndex(0);
  });

  Arbiter.subscribe('component/project_browser/init', function (data) {

    data.elem.find('select[name=continent]').on('change', function () {
      data.elem.find('form').submit();
    });

    data.elem.find('select[name=country]').on('change', function () {
      data.elem.find('form').submit();
    });

    data.elem.find('button[type=submit]').remove();

    $('.component-project-browser .lzg-teaser').matchHeight();
  });

  Arbiter.subscribe('component/article_browser/init', function (data) {

    data.elem.find('select[name=sort]').on('change', function () {
      data.elem.find('form').submit();
    });

    data.elem.find('select[name=year]').on('change', function () {
      data.elem.find('form').submit();
    });

    data.elem.find('select[name=month]').on('change', function () {
      data.elem.find('form').submit();
    });

    data.elem.find('button[type=submit]').remove();
  });

  Arbiter.subscribe('page/overview/init', function () {
    $('.blocks-row').each(function () {
      $(this).find('.block > div').matchHeight();
    });
  });

  Arbiter.subscribe('page/project/init', function () {

    $('.project-images').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Aan het laden...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      image: {
        tError: '<a href="%url%">Afbeelding #%curr%</a> kon niet worden geladen.',
        titleSrc: function(item) {
          return item.el.attr('title')
        }
      }
    });
  });

  function initParagraphs() {

    $('.paragraph-image-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      tLoading: 'Aan het laden...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1]
      },
      image: {
        tError: '<a href="%url%">Afbeelding #%curr%</a> kon niet worden geladen.',
        titleSrc: function(item) {
          return item.el.attr('title')
        }
      }
    });
  }

  Arbiter.subscribe('page/article/init', initParagraphs);
  Arbiter.subscribe('page/page/init', initParagraphs);

})();
